import i18n from '@/i18n'
import { TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib'
import moment from 'moment'
import deepcopy from 'deepcopy'
import SelectorDialog from '@/components/commons/selector/dialog/SelectorDialog.vue'
import ListTreeViewComponent from '@/components/commons/list/tree-view/ListTreeViewComponent.vue'
import NoDataAvailableComponent from '@/components/commons/no-data-available/NoDataAvailableComponent.vue'
import { getIds, toggleFullscreen } from '@/tools/functions'
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue'
// El logo de Gestya
import base64 from '@/assets/base64'
// Para exportar a formato pdf
import jsPDF from 'jspdf'
// Para exportar los canvas a diferentes formatos
import { saveAs } from 'file-saver'
// Para exportar elementos del DOM a imágenes
import domtoimage from 'dom-to-image'

export default {
  name: 'TractorDischargeContainer',
  components: {
    SelectorDialog,
    ListTreeViewComponent,
    NoDataAvailableComponent,
    AutocompleteComponent
  },
  /*
    PROPS
      height: altura del componente (number)
      width: ancho del componente (number)
      loading: para mostrar el spinner (noolean)
      title: título del componente (string)
      showSettings: para mostrar el botón de los filtros (boolean)
      showDateAndTimeSelector: para mostrar el selector de rango de fechas (boolean)
      showPercentageSwitch: para mostrar el switch de datos en porcentajes (boolean)
      showSelector: para mostrar el selector de maquinas, fincas, etc. en la parte inferior (boolean)
      chartComponentId: id del componente de los gráficos (string)
      details: detalles del gráfico (string html)
    MODEL
      selectedDateAndTimeRange: el tipo de rango de tiempo seleccionado (id)
      customDateTimeValidForm: formulario de rango personalizado (boolean)
      selectedDateAndTimeRangeCustomType: tipo de tiempo personalizado seleccionado (id)
      sinceDate: fecha en formato YYYY-MM-DD (null si no se inicializa).
      sinceTime: hora en formato hh:mm (null si no se inicializa).
      toDate: fecha en formato YYYY-MM-DD (null si no se inicializa con una fecha particular).
      toTime: hora en formato hh:mm (null si no se inicializa).
      filters: arreglo de selectores del tipo diálogo (estructura de datos del selector de los reportes)
      selects: arrego de selectores del tipo combo box (estructura de datos del selector de los reportes)
      percentageSwitchModel: donde se guarda el estado del switch (boolean)
      selectorData: arreglo de datos del selector (id - name)
      selectorModel: donde se guarda el dato elegido en el selector
      charts: arreglo de gráficos
      disableSaveButton: para dehabilitar el botón de guardar en el selector (boolean)
      updateCharts: para actulizar gráficos,
        solución provisoria hasta que se implemente el componente gráficos con modelo (boolean)
  */
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    _id: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      required: false,
      default: () => { return 500 }
    },
    width: {
      type: Number,
      required: false,
      default: () => { return 512 }
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },
    title: {
      type: String,
      required: true
    },
    showSettings: {
      type: Boolean,
      required: false,
      default: () => { return true }
    },
    showDateAndTimeSelector: {
      type: Boolean,
      required: false,
      default: () => { return true }
    },
    showPercentageSwitch: {
      type: Boolean,
      required: false,
      default: () => { return true }
    },
    showSelector: {
      type: Boolean,
      required: false,
      default: () => { return true }
    },
    details: {
      type: String,
      required: true
    }
  },
  data: () => ({
    // para mostrar/ocultar el selector de rangos de tiempo
    showDateAndTime: false,
    // valores de los menu de seleccion de fecha y hora
    menuSinceDate: null,
    menuToDate: null,
    menuSinceTime: null,
    menuToTime: null,
    // reglas de validacion
    rules: {
      required: value => !!value || `${i18n.t('required')}`,
      twoDatesValidation: (firstDate, secondDate) => firstDate.getTime() >= secondDate.getTime() || `${i18n.t('selector.dateAndTime.dateError')}`,
      twoTimesValidation: (firstTime, secondTime, firstDate, secondDate, selectedType) => (selectedType === CustomTimeRangeTypes.DATE) ||
        (selectedType === CustomTimeRangeTypes.DATE_AND_TIME && firstDate.getTime() < secondDate.getTime()) ||
        (selectedType === CustomTimeRangeTypes.DATE_AND_TIME && firstDate.getTime() === secondDate.getTime() && firstTime && secondTime && moment(firstTime, 'hh:mm').isSameOrBefore(moment(secondTime, 'hh:mm'), 'minute')) ||
        (selectedType === CustomTimeRangeTypes.TIME && firstTime && secondTime && moment(firstTime, 'hh:mm').isSameOrBefore(moment(secondTime, 'hh:mm'), 'minute')) ||
        `${i18n.t('selector.dateAndTime.timeError')}`
    },
    // referencias a las constantes
    timeRanges: TimeRanges,
    customDateAndTimeRangeType: CustomTimeRangeTypes,
    // modelo para el dialogo selector
    selectorDialogModel: {
      normalizer: (node) => {
        return {
          id: node.id,
          label: node.name,
          children: node.children
        }
      },
      data: [],
      selected: null,
      show: false,
      title: i18n.t('selector.selector'),
      singleSelect: false,
      selectAll: false,
      saveDisabled: false
    },
    // filtro del dialogo actual
    currentFilter: undefined,
    // modelo para e componente de gráficos
    chartComponentModel: {
      showRangeSelector: false,
      rangeSelectorData: []
    },
    selectedTab: null,
    // el logo de gestya
    gestyaLogoBase64: undefined,
    // el logo de la empresa
    enterpriseLogoBase64: undefined,
    // ícono del botón para ir/salir de pantalla completa
    fullscreenIcon: 'fullscreen',
    exportLoading: false,
    noDataAvailableModel: {
      text: undefined
    }
  }),
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    // Generar Base64 del logo de Gestya
    this.gestyaLogoBase64 = base64.gestya
    // Generar Base64 del logo de la empresa
    if (localStorage.getItem('logo')) {
      this.enterpriseLogoBase64 = localStorage.getItem('logo')
    } else {
      this.enterpriseLogoBase64 = base64.ledesma
    }
  },
  created () {
  },
  beforeDestroy () {
  },
  computed: {
    /**
     * Para ocultar el scrollbar en la exportación
     */
    exportCards () {
      if (this.exportLoading) {
        return 'hide-scrollbar'
      }
    },
    exportCardsRow () {
      if (this.exportLoading) {
        return 'pr-3'
      }
    },
    /**
     * Para ocultar los detalles
     */
    exportDetails () {
      if (!this.exportLoading) {
        return 'hidden'
      } else {
        return 'visible'
      }
    },
    btnDisabled () {
      return this.model.cardSets[this.selectedTab] && this.model.cardSets[this.selectedTab].cards.length <= 0
    }
  },
  methods: {
    /**
     * Cuando se guardan los filtros, se emite un evento al componente padre
     */
    saveSettings () {
      this.$emit('newFilters', {
        filters: this.model.filters,
        selects: this.model.selects,
        selectedDateAndTimeRange: this.showDateAndTimeSelector ? this.model.selectedDateAndTimeRange : undefined,
        selectedDateAndTimeRangeCustomType:
          this.showDateAndTimeSelector && this.model.selectedDateAndTimeRange === TimeRanges.CUSTOM
            ? this.model.selectedDateAndTimeRangeCustomType : undefined,
        sinceDate: this.showDateAndTimeSelector ? this.model.sinceDate : undefined,
        sinceTime: this.showDateAndTimeSelector ? this.model.sinceTime : undefined,
        toDate: this.showDateAndTimeSelector ? this.model.toDate : undefined,
        toTime: this.showDateAndTimeSelector ? this.model.toTime : undefined
      })
      this.model.showNavigationPanel = false
    },
    /**
     * Método ejecutado cuando se hace click en el botón de settings
     */
    settingsClick () {
      this.model.showNavigationPanel = true
    },
    /**
     * Para cerrar el panel del selector
     */
    close () {
      this.model.showNavigationPanel = false
    },
    /**
     * Se carga el modelo de datos del diálogo de selección
     * @param {*} filterData
     */
    selectEnitites (filterData) {
      this.currentFilter = filterData
      this.selectorDialogModel.data = this.currentFilter.data
      /*
        Si no hay datos en la prop selectorDialogItems, lo cual significaría la primer apertura del diálogo
        para ese filtro, se verifica que no haya datos en la prop selectedData (si se configuraron unos datos
        seleccionados por defecto), o sino, se setea en null.
      */
      this.selectorDialogModel.selected = filterData.selectedData.length > 0 && this.currentFilter.selectorDialogItems
        ? this.currentFilter.selectorDialogItems : (filterData.selectedData.length > 0
          ? this.getIdsFromData(filterData.selectedData, filterData.singleSelect) : null)
      this.selectorDialogModel.title = this.$t('selector.selectionDialog.title', { entityName: filterData.name })
      this.selectorDialogModel.singleSelect = filterData.singleSelect
      this.selectorDialogModel.selectAll = false
      this.selectorDialogModel.show = true
    },
    /**
     * Para obtener los ids de los datos seleccionados en el dialogo selector
     * @param {*} data
     * @param {*} singleSelect
     */
    getIdsFromData (data, singleSelect) {
      if (singleSelect) {
        return data.length === 1 ? data[0].id : null
      } else {
        const result = []
        getIds(data, result)
        return result
      }
    },
    /**
     * Acción ejecutada por el evento guardar del diálogo selector
     * @param {*} data
     */
    saveDialogAction (data) {
      this.currentFilter.selectorDialogItems = deepcopy(data)
      if (data) {
        const selectedItems = []
        if (this.currentFilter.singleSelect && data) {
          selectedItems.push(data)
        }
        const itemsCopy = deepcopy(this.currentFilter.data)
        const filteredData = itemsCopy.filter(function filterFunction (item) {
          if (data.includes(item.id)) return true
          if (item.children && item.children.length > 0) {
            return (item.children = item.children.filter(filterFunction)).length
          }
        })
        selectedItems.cleanAndUpdate(filteredData)
        this.currentFilter.selectedData.cleanAndUpdate(selectedItems)
      } else {
        this.currentFilter.selectedData.clean()
      }
      if (this.currentFilter.selectAction) this.currentFilter.selectAction()
      this.selectorDialogModel.show = false
    },
    /**
     * Acción ejecutada por e botón cancelar del diálogo selector
     */
    cancelDialogAction () {
      this.selectorDialogModel.show = false
    },
    /**
     * Función que se ejecuta con el evento changeTab del componente de los gráficos
     * @param {*} data
     */
    tabChange (index) {
      this.$emit('tabChange', index)
    },
    /**
     * Función para cambiar el ícono de cada sección
     * @param {*} show
     */
    arrowIcon (show) {
      if (show) {
        return 'arrow_drop_up'
      } else {
        return 'arrow_drop_down'
      }
    },
    /**
     * Exportar a una imagen en formato PNG
     * TO DO: detalle
     */
    async exportToImage (png = true) {
      this.exportLoading = true
      // detalles
      const divDetails = document.getElementById('tractorDischargeContainerDivDetails')
      divDetails.innerHTML = this.details
      const date = new Date()
      const title = `${this.exportFilename}_${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}_${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
      const node = document.getElementById(`export-${this.selectedTab}`)
      const blob = await domtoimage.toBlob(node, { width: node.scrollWidth, height: node.scrollHeight })
      if (png) {
        saveAs(blob, `${title}.png`)
      } else {
        saveAs(blob, `${title}.jpg`)
      }
      this.exportLoading = false
    },
    /**
     * Método para exportar a PDF
     * TO DO: detalles
     */
    async exportToPDF (print = false) {
      this.exportLoading = true
      const date = new Date()
      const title = `${this.exportFilename}_${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}_${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`

      // detalles
      const divDetails = document.getElementById('tractorDischargeContainerDivDetails')
      divDetails.innerHTML = this.details

      // tarjetas
      const node = document.getElementById(`export-${this.selectedTab}`)
      const dataUrl = await domtoimage.toPng(node, { height: node.scrollHeight })

      // GENERO EL PDF Y LO GUARDO
      const doc = jsPDF('l', 'mm', 'a4')
      doc.page = 1
      /*
      // Para establecer el tamaño de la imagen y que se vea bien
      const canvasHeight = cardsCanvas.height
      const canvasWidth = cardsCanvas.width
      const ratio = canvasHeight / canvasWidth
      */
      // Ancho y altura de la imagen en el pdf
      const pdfWidth = doc.internal.pageSize.getWidth()
      // const pdfHeight = ratio * pdfWidth
      doc.addImage(dataUrl, 'PNG', 0, 20)
      // Header
      doc.addImage(this.enterpriseLogoBase64, 'PNG', 5, 2, 50, 10)
      doc.line(5, 15, pdfWidth - 5, 15)
      // Footer
      doc.addImage(this.gestyaLogoBase64, 'PNG', 5, doc.internal.pageSize.height - 12, 50, 10)
      doc.line(5, doc.internal.pageSize.height - 15, pdfWidth - 5, doc.internal.pageSize.height - 15)

      if (print) {
        // Imprimir el pdf
        doc.autoPrint()
        window.open(doc.output('bloburl'), '_blank')
      } else {
        // Descargar el pdf
        doc.save(title)
      }

      this.exportLoading = false
    },
    /**
     * Para ir a pantalla completa
     */
    goToFullscreen () {
      const tabsContainer = document.getElementById('tractor-discharge-card-container')
      this.fullscreenIcon = toggleFullscreen(tabsContainer)
    },
    /**
     * Ícono del botón de pantalla completa
     */
    switchFullscreenIcon () {
      if (!document.fullscreenElement && !document.mozFullScreenElement &&
        !document.webkitFullscreenElement && !document.msFullscreenElement) {
        this.fullscreenIcon = 'fullscreen'
      } else {
        this.fullscreenIcon = 'fullscreen_exit'
      }
    },
    selectedFunction (newValue) {
      this.model.selectorModel = newValue && newValue.id ? newValue : this.selectorThingsSelected
    },
    selectedTractorFunction (newValue, select) {
      select.model = newValue && newValue[select.value] ? newValue[select.value] : newValue
    }
  },
  watch: {
    'model.update': {
      handler: function (val) {
        if (val) {
          const chartRef = this.$refs[this.chartComponentId]
          if (chartRef) {
            chartRef.resetAll()
          }
        }
      },
      deep: true
    }
  }
}
