import TractorDischargeContainer from '@/components/tractor-discharge/tractor-discharge-container/TractorDischargeContainer.vue'
import i18n from '@/i18n'
import Vue from 'vue'
import { TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib'
import activityApi from '@/api/activity.api'
import enterpriseApi from '@/api/enterprise.api'
import tractorDischargeApi from '@/api/tractorDischarge.api'
import thingApi from '@/api/thing.api'
import { selectorDateTimeValidation, getDateTimeRange, getTimeRangeNames } from '@/tools/functions'
import { configurationService } from '@/business/configurationService'

// constante de update de gráficos (en minutos)
const REFRESH = 10

export default {
  name: 'TractorDischargeComponent',
  components: {
    TractorDischargeContainer
  },
  props: {
    _id: {
      type: String,
      required: true
    },
    sector: {
      type: String,
      required: false,
      default: () => { return null }
    }
  },
  data: () => ({
    report: 'TRACTOR_DISCHARGE',
    chartComponentId: 'tractor-discharge',
    cardContainerModel: {
      // para mostrar/ocultar los filtros
      showNavigationPanel: false,
      // rangos de tiempo
      dateAndTimeRanges: [
        // inicio de zafra, jornada diaria, personalizado
        // {
        //   id: TimeRanges.HARVEST_START,
        //   text: i18n.t('selector.dateAndTime.harvestStart'),
        //   custom: false
        // },
        {
          id: TimeRanges.LAST_HOUR,
          text: i18n.t('selector.dateAndTime.lastHour'),
          custom: false
        },
        {
          id: TimeRanges.LAST_SIX_HOUR,
          text: i18n.t('selector.dateAndTime.lastSixHours'),
          custom: false
        },
        {
          id: TimeRanges.TODAY,
          text: i18n.t('selector.dateAndTime.today'),
          custom: false
        },
        {
          id: TimeRanges.YESTERDAY,
          text: i18n.t('selector.dateAndTime.yesterday'),
          custom: false
        },
        {
          id: TimeRanges.LAST_WEEK,
          text: i18n.t('selector.dateAndTime.lastWeek'),
          custom: false
        },
        {
          id: TimeRanges.ENTERPRISE,
          text: '',
          custom: false
        },
        {
          id: TimeRanges.ENTERPRISE_PREVIOUS,
          text: i18n.t('selector.dateAndTime.previousShift'),
          custom: false
        }
        // {
        //   id: TimeRanges.CUSTOM,
        //   text: i18n.t('selector.dateAndTime.custom'),
        //   custom: true
        // }
      ],
      //  tipos de rangos personalizados
      dateAndTimeCustomRangeTypes: [
        {
          text: i18n.t('selector.dateAndTime.customTypes.dateAndTime'),
          value: CustomTimeRangeTypes.DATE_AND_TIME
        },
        {
          text: i18n.t('selector.dateAndTime.customTypes.date'),
          value: CustomTimeRangeTypes.DATE
        }
      ],
      selectedDateAndTimeRange: TimeRanges.LAST_HOUR,
      customDateTimeValidForm: false,
      selectedDateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
      sinceDate: null,
      sinceTime: null,
      toDate: null,
      toTime: null,
      filters: [
        {
          id: 'activity',
          name: i18n.t('headers.activity'),
          show: false,
          disabled: false,
          showDialog: false,
          singleSelect: true,
          data: [],
          selectedData: [],
          selectAction: undefined
        },
        {
          id: 'type',
          name: i18n.t('headers.thingType'),
          show: false,
          disabled: false,
          showDialog: false,
          singleSelect: false,
          data: [],
          selectedData: [],
          selectAction: undefined
        }
      ],
      selects: [],
      percentageSwitchModel: false,
      selectorData: [],
      selectorModel: null,
      cardSets: [],
      disableSaveButton: false,
      update: false,
      intervalId: null
    },
    timestampFrom: null,
    timestampTo: null,
    types: [],
    // arreglo de arreglos de datos (fincas, frentes de trabajo, máquinas, rrhh)
    selectorsData: [[], [], [], []],
    // arreeglo de los valores seleccionados
    selectorsModel: [null, null, null, null],
    currentChartIndex: 0,
    loading: false,
    chartName: '',
    all: {
      id: 'ALL',
      name: i18n.t('all')
    },
    data: [],
    activities: []
  }),
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    let initialLoad = true
    this.loading = true

    // actividades
    const activityResponse = await activityApi.getAllForSelector(this.sector, this.report)
    if (Array.isArray(activityResponse.data)) {
      this.cardContainerModel.filters[0].data.cleanAndUpdate(activityResponse.data)
      if (activityResponse.data.length === 1 && activityResponse.data[0].key === 'NO_ACTIVITY') {
        this.cardContainerModel.filters[0].selectedData = this.cardContainerModel.filters[0].data.filter(s => s.key === 'NO_ACTIVITY')
        this.cardContainerModel.filters[0].disabled = true
        this.cardContainerModel.filters[0].hide = true
        await this.activityFilterCallback()
      }
    }

    // llamado a callback de actividades
    this.cardContainerModel.filters[0].selectAction = this.activityFilterCallback.bind(this)

    // fecha personaliza solo para los superadmin
    // let admin = JSON.parse(localStorage.getItem('admin'))
    // if (admin) {
    //   this.cardContainerModel.dateAndTimeRanges.push({
    //     id: TimeRanges.CUSTOM,
    //     text: i18n.t('selector.dateAndTime.custom'),
    //     custom: true
    //   })
    //   this.cardContainerModel.dateAndTimeRanges.push({
    //     id: TimeRanges.HARVEST_START,
    //     text: i18n.t('selector.dateAndTime.harvestStart'),
    //     custom: false
    //   })
    // }
    // tiempo de jornada diaria
    if (JSON.parse(localStorage.getItem('enterpriseTimeSpan'))) {
      this.cardContainerModel.dateAndTimeRanges.find(x => x.id === TimeRanges.ENTERPRISE).text = i18n.t('selector.dateAndTime.dailyShift')
    } else {
      const enterpriseTimeSpan = (await enterpriseApi.getEnterpriseCustomTimeSpan()).data
      if (enterpriseTimeSpan) {
        localStorage.setItem('enterpriseTimeSpan', JSON.stringify(enterpriseTimeSpan))
        this.cardContainerModel.dateAndTimeRanges.find(x => x.id === TimeRanges.ENTERPRISE).text = i18n.t('selector.dateAndTime.dailyShift')
      } else {
        this.cardContainerModel.dateAndTimeRanges.splice(this.cardContainerModel.dateAndTimeRanges.indexOf(x => x.id === TimeRanges.ENTERPRISE) - 1, 1)
        this.cardContainerModel.selectedDateAndTimeRange = TimeRanges.HARVEST_START
      }
    }

    // tiempo de inicio de zafra
    if (!JSON.parse(localStorage.getItem('harvestStartTime'))) {
      const harvestStartTime = (await enterpriseApi.getEnterpriseHarvestStartTime()).data
      if (harvestStartTime) {
        localStorage.setItem('harvestStartTime', JSON.stringify(harvestStartTime))
      } else {
        const havevestStartIndex = this.cardContainerModel.dateAndTimeRanges.indexOf(x => x.id === TimeRanges.HARVEST_START)
        if (havevestStartIndex !== -1) {
          this.cardContainerModel.dateAndTimeRanges.splice(havevestStartIndex - 1, 1)
          this.cardContainerModel.selectedDateAndTimeRange = TimeRanges.CUSTOM
          initialLoad = false
        }
      }
    }

    // configuración
    await this.getConfiguration(initialLoad)

    this.loading = false
    // ejecuto la validación del selector, si es correcta, se carga el gráfico, sino abro el selector
    if (initialLoad) {
      // creo los gráficos
      this.getData(this.cardContainerModel)
    } else {
      this.cardContainerModel.showNavigationPanel = true
    }
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
    this.intervalId = null
  },
  destroyed () {
  },
  computed: {
    /**
     * Detalles del gráfico (html)
     */
    details () {
      const timeNames = getTimeRangeNames()
      const activities = this.cardContainerModel.filters[0].selectedData.map(a => a.name).toString()
      const types = this.cardContainerModel.filters[1].selectedData.map(t => t.name).toString()
      let details = `<h3>${this.$t('chart.chart')}: ${this.$t('dashboard.tractorDischarge')}</h3>`
      if (this.chartName) {
        details += `<h5>${this.$t('level')}: ${this.chartName}</h5>`
      }
      if (this.cardContainerModel.filters && this.cardContainerModel.filters[0].data[0] && this.cardContainerModel.filters[0].data[0].key !== 'NO_ACTIVITY') {
        details += `<h5>${this.$t('activity')}: ${activities}</h5>`
      }
      if (this.cardContainerModel.filters && this.cardContainerModel.filters[1].data[0]) {
        details += `<h5>${this.$t('type')}: ${types}</h5>`
      }
      details += `<h5>${this.$t('selector.dateAndTime.label')}: ${timeNames[this.cardContainerModel.selectedDateAndTimeRange]}</h5>`
      return details
    }
  },
  methods: {
    async getData (filters, update = false) {
      configurationService.save(this._id,
        {
          activities: filters.filters[0].selectedData,
          types: filters.filters[1].selectedData,
          selectedDateAndTimeRange: filters.selectedDateAndTimeRange,
          selectedDateAndTimeRangeCustomType: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.selectedDateAndTimeRangeCustomType : null,
          sinceDate: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.sinceDate : null,
          sinceTime: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.sinceTime : null,
          toDate: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.toDate : null,
          toTime: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.toTime : null
        })
      clearInterval(this.intervalId)
      await this.getDashboardData(filters, update)
      const that = this
      that.intervalId = setInterval(async function () {
        await that.updateAllCharts()
      }, REFRESH * 60 * 1000)
    },
    async getDashboardData (filters, update) {
      this.loading = true

      this.selectedDateAndTimeRange = filters.selectedDateAndTimeRange

      const conversion = getDateTimeRange(filters.selectedDateAndTimeRange, filters.selectedDateAndTimeRangeCustomType,
        filters.sinceDate, filters.sinceTime, filters.toDate, filters.toTime)
      this.timestampFrom = conversion.tFrom
      this.timestampTo = conversion.tTo
      this.activities = Array.from(filters.filters[0].selectedData)
      const activitiesId = this.activities.map(a => a.id)
      // request de tipos de cosas
      this.types = Array.from(filters.filters[1].selectedData)
      const typesKey = this.types.map(t => t.key)
      const dashboardResponse = await tractorDischargeApi.getTractorDischargeAll(activitiesId, typesKey,
        this.timestampFrom, this.timestampTo, this.selectedDateAndTimeRange)
      this.cardContainerModel.cardSets.cleanAndUpdate(dashboardResponse.data.cardSets)
      this.data = dashboardResponse.data.cardSets
      // actualizo la lista de datos para los selectores
      this.selectorsData[0].cleanAndUpdate([this.all].concat(dashboardResponse.data.farms))
      this.selectorsData[1].cleanAndUpdate([this.all].concat(dashboardResponse.data.workFronts))
      this.selectorsData[2].cleanAndUpdate([this.all].concat(dashboardResponse.data.machines))
      this.selectorsData[3].cleanAndUpdate([this.all].concat(dashboardResponse.data.rrhhs))

      // si el valor seleccionado no está presente en la lista, debo reiniciar dicho valor
      this.selectorsModel.forEach((model, index) => {
        if (!this.selectorsData[index].find(item => item.id === model)) {
          model = null
        }
      })

      if (update) {
        // MODIFICACIÓN DE LOS FILTROS
        // si es una actualización (se modificaron los filtros, actualizo los datos del selector actual)
        this.cardContainerModel.selectorData.cleanAndUpdate(this.selectorsData[this.currentChartIndex])
        this.cardContainerModel.selectorModel = this.selectorsModel[this.currentChartIndex]

        this.cardContainerModel.update = true
        const that = this
        Vue.nextTick(function () {
          that.cardContainerModel.update = false
        })
      } else {
        // PRIMERA CARGA DE LOS GRÁFICOS
        this.cardContainerModel.selectorData.cleanAndUpdate(this.selectorsData[0])
        this.chartName = this.cardContainerModel.cardSets[0].name
      }
      this.loading = false
    },
    filterCurrentCardSet (index) {
      if (!this.cardContainerModel.selectorModel || this.cardContainerModel.selectorModel.id === 'ALL') {
        const cardSet = this.generateCardSet(index, null)
        this.cardContainerModel.cardSets[index] = cardSet
      } else {
        const cardSet = this.generateCardSet(index, this.cardContainerModel.selectorModel)
        this.cardContainerModel.cardSets[index] = cardSet
      }

      /*
        SOLUCIÓN PROVISORIA PARA LA ACTUALIZACIÓN DE GRÁFICOS:
        CUANDO SE IMPLEMETE EL MODELO EN EL COMPONENTE GENÉRICO, LA ACTUALIZACIÓN SE REALIZARÁ DE FORMA INMEDIATA
        CUANDO CAMBIEN LOS DATOS DEL GRÁFICO.
        Por ahora se definió un variable booleana del tipo "semáforo", es decir, cuando está en true, ejecuta la actualización
      */
      this.cardContainerModel.update = true
      const that = this
      Vue.nextTick(function () {
        that.cardContainerModel.update = false
      })
    },
    generateCardSet (index, selected) {
      const cardSet = Object.assign({}, this.data[index])
      if (selected && selected.id !== 'ALL') {
        cardSet.cards = cardSet.cards.filter(c => c.id === selected.id)
      }
      return cardSet
    },
    /**
     * Función ejecutada cuando se cambia de tab (se cambia de gráfico)
     * @param {*} index
     */
    tabChange (index) {
      if (index !== this.currentChartIndex) {
        // nombre del gráfico (para los detalles)
        this.chartName = this.cardContainerModel.cardSets[index].name
        // cambio el valor del selector y guardo el valor para el gráfico actual
        this.cardContainerModel.selectorData.cleanAndUpdate(this.selectorsData[index])
        // currentChart aún tiene los valores del gráfico anterior, guardo el valor
        this.selectorsModel[this.currentChartIndex] = this.cardContainerModel.selectorModel
        // al fnal de todo, actualizo el valor de currentChart
        this.currentChartIndex = index
        if (this.cardContainerModel.selectorModel !== this.selectorsModel[index]) {
          // si hay valor seleccionado, lo seteo
          this.cardContainerModel.selectorModel = this.selectorsModel[index]
        } else {
          this.updateChart()
        }
      }
    },
    /**
     * Para actualizar el gráfico actual cuando se usa el selector
     */
    async updateChart () {
      this.loading = true

      // filtra los datos del gráfico actual de acuerdo a la selección
      this.filterCurrentCardSet(this.currentChartIndex)

      this.loading = false
    },
    /**
     * Este método es ejecutado cuando se actualizan todos los gráficos por el timer del "autoreporte"
     */
    async updateAllCharts () {
      this.loading = true
      // primero, actualizo los valores de los timestamps
      const conversion = getDateTimeRange(this.cardContainerModel.selectedDateAndTimeRange,
        this.cardContainerModel.selectedDateAndTimeRangeCustomType, this.cardContainerModel.sinceDate,
        this.cardContainerModel.sinceTime, this.cardContainerModel.toDate, this.cardContainerModel.toTime)
      this.timestampFrom = conversion.tFrom
      this.timestampTo = conversion.tTo

      // GENERO LOS DATOS NUEVAMENTE
      const activitiesId = this.activities.map(a => a.id)
      const dashboardResponse = await tractorDischargeApi.getTractorDischargeAll(activitiesId, this.types.map(t => t.key),
        this.timestampFrom, this.timestampTo, this.selectedDateAndTimeRange)
      this.cardContainerModel.cardSets.cleanAndUpdate(dashboardResponse.data.cardSets)
      this.data = dashboardResponse.data.cardSets

      // actualizo la lista de datos para los selectores
      this.selectorsData[0].cleanAndUpdate([this.all].concat(dashboardResponse.data.farms))
      this.selectorsData[1].cleanAndUpdate([this.all].concat(dashboardResponse.data.workFronts))
      this.selectorsData[2].cleanAndUpdate([this.all].concat(dashboardResponse.data.machines))
      this.selectorsData[3].cleanAndUpdate([this.all].concat(dashboardResponse.data.rrhhs))
      // si el valor seleccionado no está presente en la lista, debo reiniciar dicho valor
      this.selectorsModel.forEach((model, index) => {
        if (!this.selectorsData[index].find(item => item.id === model)) {
          model = null
        }
      })

      // filtra los datos del gráfico actual de acuerdo a la selección
      this.filterCurrentCardSet(this.currentChartIndex)
      this.loading = false
    },
    /**
     * Para validar el selector de filtros y habilitar/deshabilitar el botón para guardar
     */
    validateSelector () {
      const that = this
      Vue.nextTick(function () {
        const activitySelected = that.cardContainerModel.filters[0].selectedData
        const typeSelected = that.cardContainerModel.filters[1].selectedData
        const customTimeValidation = selectorDateTimeValidation(that.cardContainerModel.selectedDateAndTimeRange,
          that.cardContainerModel.selectedDateAndTimeRangeCustomType, that.cardContainerModel.customDateTimeValidForm,
          that.cardContainerModel.sinceDate, that.cardContainerModel.sinceTime, that.cardContainerModel.toDate, that.cardContainerModel.toTime)
        that.cardContainerModel.disableSaveButton =
        activitySelected.length === 0 || typeSelected.length === 0 || customTimeValidation
      })
    },
    async activityFilterCallback () {
      if (this.cardContainerModel.filters[0].selectedData.length > 0) {
        this.cardContainerModel.filters[1].selectedData = []
        this.cardContainerModel.filters[1].disabled = false
        const thingTypesResponse =
          await thingApi.getThingTypesByActivity(this.cardContainerModel.filters[0].selectedData.map(a => a.id), this.sector, this.report)
        this.cardContainerModel.filters[1].data.cleanAndUpdate(thingTypesResponse.data)
      } else {
        this.cardContainerModel.filters[1].selectedData = []
        this.cardContainerModel.filters[1].disabled = true
      }
    },
    /**
     * Para obtener la configuración de filtros
     * Esta función se ejecuta en mounted
     * @param {*} init
     * @returns
     */
    async getConfiguration (init) {
      let initialLoad = init
      const config = await configurationService.get(this._id)
      if (config) {
        // actividades
        if (this.cardContainerModel.filters[0].data.length > 0) {
          if (config.data.activities && config.data.activities.length > 0) {
            config.data.activities.forEach(activity => {
              if (this.cardContainerModel.filters[0].data.find(a => a.id === activity.id) && !this.cardContainerModel.filters[0].selectedData.find(a => a.id === activity.id)) {
                this.cardContainerModel.filters[0].selectedData.push(activity)
              }
            })
          }

          await this.activityFilterCallback()
          if (config.data.types && config.data.types.length > 0) {
            config.data.types.forEach(type => {
              if (this.cardContainerModel.filters[1].data.find(t => t.id === type.id) && !this.cardContainerModel.filters[1].selectedData.find(t => t.id === type.id)) {
                this.cardContainerModel.filters[1].selectedData.push(type)
              }
            })
          }
        } else {
          initialLoad = false
        }
        // rango de tiempo
        if (config.data.selectedDateAndTimeRange && this.cardContainerModel.dateAndTimeRanges.find(range => range.id === config.data.selectedDateAndTimeRange)) {
          this.cardContainerModel.selectedDateAndTimeRange = config.data.selectedDateAndTimeRange
          // custom
          if (config.data.selectedDateAndTimeRange === TimeRanges.CUSTOM) {
            if (config.data.selectedDateAndTimeRangeCustomType &&
              config.data.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE) {
              this.cardContainerModel.selectedDateAndTimeRangeCustomType = config.data.selectedDateAndTimeRangeCustomType
              if (config.data.sinceDate && config.data.toDate) {
                this.cardContainerModel.sinceDate = config.data.sinceDate
                this.cardContainerModel.toDate = config.data.toDate
              } else {
                initialLoad = false
              }
            } else if (config.data.selectedDateAndTimeRangeCustomType &&
              config.data.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE_AND_TIME) {
              this.cardContainerModel.selectedDateAndTimeRangeCustomType = config.data.selectedDateAndTimeRangeCustomType
              if (config.data.sinceDate && config.data.toDate && config.data.sinceTime && config.data.toTime) {
                this.cardContainerModel.sinceDate = config.data.sinceDate
                this.cardContainerModel.sinceTime = config.data.sinceTime
                this.cardContainerModel.toDate = config.data.toDate
                this.cardContainerModel.toTime = config.data.toTime
              } else {
                initialLoad = false
              }
            } else {
              initialLoad = false
            }
          }
        }
      } else { // CARGA SIN CONFIG
        // para selector CON actividad
        if (this.cardContainerModel.filters[0].data.length > 1) {
          await this.activityFilterCallback()
          // para selector SIN actividad
        } else if (this.cardContainerModel.filters[0].data.length === 1 && this.cardContainerModel.filters[0].data[0].key === 'NO_ACTIVITY') {
          this.cardContainerModel.filters[0].selectedData.cleanAndUpdate([this.cardContainerModel.filters[0].data[0]])
        } else {
          initialLoad = false
        }
      }
      return initialLoad
    }
  },
  watch: {
    'cardContainerModel.filters': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'cardContainerModel.selectedDateAndTimeRange': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'cardContainerModel.selectedDateAndTimeRangeCustomType': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'cardContainerModel.sinceDate': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'cardContainerModel.sinceTime': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'cardContainerModel.toDate': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'cardContainerModel.toTime': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'cardContainerModel.selectorModel': {
      handler: function () {
        this.updateChart()
        this.selectorsModel[this.currentChartIndex] = this.cardContainerModel.selectorModel
      },
      deep: true
    }
  }
}
