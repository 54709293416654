import service from '@/middleware'

const tractorDischargeApi = {
  async getTractorDischargeAll (activities, types, from, to, timeRange) {
    const body = {
      activities,
      types,
      from,
      to,
      timeRange
    }
    const result = await service.http.post('/api/tractor-discharge/all', body)
    return result
  }
}

export default tractorDischargeApi
